<!-- 特惠购车 -->
<template>
  <div class="SpecialCar">
    <p class="title">特惠购车</p>
    <ul class="list-wrap">
      <li
        class="item"
        v-for="(item, index) in specialList"
        :key="index"
        @click="nextPage(item)"
      >
        <img :src="item.photoUrl" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SpecialCar",
  props: {
    specialList: { type: Array }
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    nextPage(item) {
      location.href = item.adUrl;
    }
  }
};
</script>
<style lang="scss" scoped>
.SpecialCar {
  margin: 0.18rem 0.18rem 0;
  .title {
    line-height: 0.2rem;
    margin-bottom: 0.16rem;
    font-size: 0.2rem;
    font-weight: bold;
    color: $mainBlack;
  }
  .list-wrap {
    display: flex;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .item {
      width: 1.3rem;
      height: 1.6rem;
      margin-right: 0.1rem;
      img {
        width: 1.3rem;
        height: 1.6rem;
        border-radius: 0.08rem;
      }
    }
  }
}
</style>
