<!-- tab -->
<template>
  <div class="CircleTab">
    <div
      :class="['item', { active: activeTab == item.code }]"
      v-for="item in list"
      :key="item.code"
      @click="tabChange(item.code)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CircleTab",
  props: {
    list: { type: Array },
    active: { type: String }
  },
  data() {
    return {
      activeTab: this.active
    };
  },
  methods: {
    tabChange(code) {
      this.activeTab = code;
      this.$emit("tabChange", code);
    }
  }
};
</script>
<style lang="scss" scoped>
.CircleTab {
  .item {
    display: inline-block;
    font-size: 0.13rem;
    color: $lightGrey;
    padding: 0.075rem 0.13rem;
    background: #ebebeb;
    border-radius: 0.15rem;
    margin-right: 0.12rem;
  }
  .active {
    background: $mainRed;
    color: #ffffff;
  }
}
</style>
