<!-- 分期购车/首页-卡片 -->
<template>
  <div class="cardItem" @click="nextPage">
    <div class="topImg">
      <img class="tag" v-show="source.productRateDesc === '0.00%'" src="@/assets/images/0xi.png" />
      <img class="carImg" :src="source.serialImg" alt="" />
    </div>
    <p class="name van-ellipsis">{{ source.serialName }}</p>
    <p class="price">指导价：{{ source.referPrice }}</p>
    <div class="info">
      <div class="subItem">
        <p class="value">{{ source.downPaymentRatio }}</p>
        <p class="title">首付</p>
      </div>
      <div class="subItem">
        <p class="value">{{ source.productRateDesc }}</p>
        <p class="title">利率</p>
      </div>
      <div class="subItem">
        <p class="value">{{ source.productLoanPeriodDesc }}</p>
        <p class="title">期数</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    source: { type: Object }
  },
  methods: {
    nextPage () {
      this.$router.push({
        name: "CarDetail",
        query: { serialId: this.source.serialId }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.cardItem {
  border-radius: 0.08rem;
  background: #fff;
  margin-bottom: 0.1rem;
  box-shadow: 0 0.02rem 0.06rem 0 rgba(105, 105, 152, 0.03);
  .topImg {
    border-radius: 0.08rem 0.08rem 0 0;
    position: relative;
    background-image: linear-gradient(180deg, #fdfefe 0%, #f7f8f9 100%);
    text-align: center;
    padding-top: 0.08rem;
    .tag {
      position: absolute;
      left: 0;
      top: 0;
      width: 0.6rem;
      height: 0.16rem;
    }
    .carImg {
      height: 0.85rem;
      border-radius: 0.08rem 0.08rem 0 0;
    }
  }
  .name {
    line-height: 0.2rem;
    font-size: 0.14rem;
    font-weight: bold;
    color: $mainBlack;
    padding: 0.12rem 0.12rem 0.04rem;
  }
  .price {
    line-height: 0.17rem;
    padding: 0 0.12rem;
    font-size: 0.12rem;
    color: #3a3d46;
  }
  .info {
    padding: 0.12rem 0.12rem 0.16rem;
    margin-top: 0.11rem;
    border-top: 1px solid #f1f2f6;
    display: flex;
    justify-content: space-between;
    font-size: 0.11rem;
    line-height: 0.16rem;
    .subItem {
      text-align: center;
      .value {
        color: #ff4848;
        margin-bottom: 0.04rem;
      }
    }
  }
}
</style>
