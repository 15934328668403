<!-- 易鑫金选 -->
<template>
  <div class="GoldCar">
    <p class="title">易鑫金选</p>
    <div
      class="item"
      v-for="item in carList"
      :key="item.serialId"
      @click="nextPage(item)"
    >
      <div class="imgWrap">
        <img class="carImg" :src="item.serialImg" alt="" />
      </div>
      <div class="info van-ellipsis">
        <p class="txt van-ellipsis">{{ item.serialName }}</p>
        <p class="price">{{ item.referPrice }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoldCar",
  props: {
    carList: { type: Array }
  },
  methods: {
    nextPage(item) {
      this.$router.push({
        name: "CarDetail",
        query: { serialId: item.serialId }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
// 无用样式，做重定向用
.test{
  background: url(../../../assets/images/temp_bg.png) no-repeat;
}
.GoldCar {
  background: url(../../../assets/images/goldBg.png) no-repeat;
  background-size: cover;
  box-shadow: 0 0.02rem 0.06rem 0 rgba(105, 105, 152, 0.03);
  border-radius: 0.08rem;
  padding: 0.12rem 0.08rem 0.08rem;
  font-size: 0.14rem;
  font-weight: bold;
  color: $mainBlack;
  margin-bottom: 0.1rem;
  .title {
    line-height: 0.2rem;
    margin-bottom: 0.08rem;
  }
  .item {
    background: #ffffff;
    border-radius: 0.04rem;
    margin-top: 0.04rem;
    padding: 0.07rem 0.06rem;
    display: flex;
    align-items: center;
    .imgWrap {
      width: 0.36rem;
      height: 0.36rem;
      background: #f7f8f9;
      margin-right: 0.08rem;
      .carImg {
        width: 0.36rem;
        height: 0.36rem;
      }
    }
    .info {
      font-size: 0.13rem;
      line-height: 0.18rem;
      .price {
        color: #6e727a;
        font-weight: normal;
        margin-top: 0.04rem;
        font-size: 0.1rem;
        line-height: 0.14rem;
      }
    }
  }
  .item:nth-child(2) {
    .price,
    .txt {
      color: $mainRed !important;
    }
  }
}
</style>
