<!-- 首页-分期购车模块 -->
<template>
  <div class="homeCar">
    <CircleTab :list="tabList" active="1" @tabChange="tabChange" />
    <div class="listWrap">
      <div class="left">
        <GoldCar
          v-show="activeKey === '1' && goldList.length && !adUrl"
          :carList="goldList"
        />
        <div class="ads" v-show="adUrl">
          <img :src="adUrl" alt="" />
        </div>
        <template class="item" v-for="item in leftList">
          <Card :source="item" :key="item.serialId" />
        </template>
      </div>
      <div class="right">
        <template class="item" v-for="item in rightList">
          <Card :source="item" :key="item.serialId" />
        </template>
      </div>
    </div>
    <div class="c_moreInfo" @click="nextPage">查看更多</div>
  </div>
</template>

<script>
import CircleTab from "@/components/CircleTab";
import GoldCar from "./GoldCar";
import Card from "@/components/Card";
import { getHotCarList } from "@/api/api_home";
export default {
  name: "homeCar",
  props: {
    yxJinxuanbanner: {
      type: Array
    }
  },
  data() {
    return {
      activeKey: "1",
      tabList: [
        { name: "热门", code: "1" },
        { name: "0息购车", code: "2" }
      ],
      goldList: [], //  易鑫金选
      leftList: [],
      rightList: [],
      currentCity: {}
    };
  },
  computed: {
    adUrl() {
      return this.yxJinxuanbanner[0] && this.yxJinxuanbanner[0]["photoUrl"];
    }
  },
  created() {
    // 获取当前定位信息
    const P = this.$store.state.position;
    if (P.cityId) {
      this.currentCity = P;
      this.getCarList();
    } else {
      this.$Geolocation(position => {
        this.currentCity = position;
        this.getCarList();
        this.$store.commit("setpPosition", position);
      });
    }
  },
  methods: {
    nextPage() {
      this.$router.push({ name: "CarList" });
    },
    tabChange(code) {
      this.activeKey = code;
      this.getCarList();
    },
    // 获取车型列表
    getCarList() {
      getHotCarList({
        searchType: this.activeKey, // 1热门，2零息
        cityId: this.currentCity.cityId
      }).then(res => {
        if (res.code === 0) {
          this.goldList = res.data.info.topOtherList || [];
          const top5List = res.data.info.top5List;
          if (this.activeKey === "2" && top5List.length > 6) {
            top5List.length = 6;
          }
          const oddList = [];
          const evenList = top5List.filter((item, index) => {
            if (index % 2 > 0) {
              oddList.push(item);
            }
            return index % 2 === 0;
          });
          if (this.activeKey === "1" && this.goldList.length) {
            // 热门
            this.leftList = oddList;
            this.rightList = evenList;
          } else {
            // 0息，没有易鑫金选
            this.leftList = evenList;
            this.rightList = oddList;
          }
        }
      });
    }
  },
  components: { CircleTab, GoldCar, Card }
};
</script>
<style lang="scss" scoped>
.homeCar {
  margin: 0.18rem 0.18rem 0.16rem;
  .listWrap {
    display: flex;
    margin-top: 0.16rem;
    .left,
    .right {
      width: calc(50% - 5px);
    }
    .left {
      margin-right: 10px;
    }
    .ads {
      width: 100%;
      height: 2.6rem;
      margin-bottom: 0.1rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 0.08rem;
      }
    }
  }
  .c_moreInfo {
    margin: 0;
  }
}
</style>
