<!-- 首页 -->
<template>
  <div class="Home">
    <!-- 顶部搜索 -->
    <div class="topFixed" :style="`top:${isShowDownLoad ? '.48rem' : '0'}`">
      <img class="logo" src="@/assets/images/yx_Logo.png" />
      <Search @focus="pageToSearch" placeholder="请输入品牌或车系" />
      <div @click="pageToCity">
        <span class="city">{{ position.cityName }}</span>
        <img class="down" src="@/assets/images/down.png" />
      </div>
    </div>
    <!-- 背景渐变区域 -->
    <div class="gradient">
      <!-- 首页轮播 -->
      <HomeSwiper :bannerList="focusBanner" />
      <!-- 特惠购车 -->
      <SpecialCar
        v-if="discountsBanner.length"
        :specialList="discountsBanner"
      />
      <!-- 分期购车 -->
      <HomeCar :yxJinxuanbanner="yxJinxuanbanner" />
    </div>
    <HomeSwiper v-if="middleBanner.length" :bannerList="middleBanner" />
    <!-- 合作银行 -->
    <p class="bankTitle">合作银行</p>
    <div class="bankWrap">
      <div
        class="item"
        v-for="(item, index) in bankList"
        :key="item.id"
        :style="index % 2 ? 'margin-left:5px' : 'margin-right:5px'"
        @click="pageToDetal(item)"
      >
        <img :src="item.longFunderLogo" />
      </div>
    </div>
    <div class="c_moreInfo msrB16" @click="pageToBank">查看更多</div>
    <HomeSwiper v-if="bottomBanner.length" :bannerList="bottomBanner" />
    <div class="reference">
      <p @click="pageToOne">京ICP备15006372号-2</p>
      <p @click="pageToTwo">京公网安备11010802020005号</p>
    </div>
    <!-- 底部菜单 -->
    <Footer :active="0" />
  </div>
</template>

<script>
import Search from "@/components/Search";
import HomeSwiper from "@/components/Swiper";
import HomeCar from "./components/HomeCar";
import SpecialCar from "./components/SpecialCar.vue";
import { funderList, getBannerList } from "@/api/api_home";
import { mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      bankList: [],
      discountsBanner: [],
      focusBanner: [],
      middleBanner: [],
      bottomBanner: [],
      yxJinxuanbanner: []
    };
  },
  computed: {
    ...mapState(["isShowDownLoad", "position"])
  },
  watch: {
    position: {
      handler() {
        if (this.position.cityId) {
          this.getImgList();
        }
      },
      immediate: true
    }
  },
  created() {
    this.initData();
  },
  methods: {
    // 获取广告
    getImgList() {
      getBannerList({
        cityCode: this.position.cityId,
        adPage: 1
      }).then(res => {
        if (res.code === 0) {
          const info = res.data.info;
          this.focusBanner = info.focusBanner;
          this.discountsBanner = info.discountsBanner;
          this.middleBanner = info.middleBanner;
          this.bottomBanner = info.bottomBanner;
          this.yxJinxuanbanner = info.yxJinxuanbanner;
        }
      });
    },
    // 获取资方列表
    async initData() {
      const res = await funderList({});
      if (res.code === 0) {
        const list = res.data.info;
        if (list.length > 6) {
          list.length = 6;
        }
        this.bankList = list;
      }
    },
    pageToSearch() {
      this.$router.push({ name: "SearchResult" });
    },
    pageToDetal(item) {
      this.$router.push({ name: "BankDetail", query: { id: item.id } });
    },
    pageToBank() {
      this.$router.push({ name: "BankList" });
    },
    pageToCity() {
      this.$router.push({ name: "CitySelect" });
    },
    pageToOne() {
      location.href = "https://beian.miit.gov.cn/";
    },
    pageToTwo() {
      location.href =
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020005";
    }
  },
  components: { HomeSwiper, HomeCar, Search, SpecialCar }
};
</script>
<style lang="scss" scoped>
.Home {
  padding-bottom: 1rem;
  .topFixed {
    padding: 0.09rem 0.12rem 0.1rem 0.18rem;
    display: flex;
    align-items: center;
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    .logo {
      width: 0.96rem;
      height: 0.27rem;
      margin-right: 0.12rem;
    }
    .Search {
      flex: 1;
    }
    .city {
      margin: 0 0.06rem 0 0.12rem;
      font-size: 0.13rem;
    }
    .down {
      width: 0.08rem;
      height: 0.08rem;
    }
  }
  .bankTitle {
    font-size: 0.2rem;
    font-weight: bold;
    color: $mainBlack;
    margin: 0.16rem 0.18rem;
    line-height: 0.28rem;
  }
  .gradient {
    padding-top: 0.61rem;
    background-image: linear-gradient(180deg, #fff, rgb(240, 241, 243));
  }
  .bankWrap {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.18rem;
    .item {
      width: calc(50% - 5px);
      background: #fff;
      box-shadow: 0 0.02rem 0.06rem 0 rgba(105, 105, 152, 0.03);
      border-radius: 0.08rem;
      height: 0.6rem;
      margin-bottom: 0.1rem;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        border-radius: 0.08rem;
      }
    }
  }
  .msrB16 {
    margin-bottom: 0.16rem;
  }
  .reference {
    font-size: 0.12rem;
    color: #8c8c8c;
    margin: 0.3rem 0 0.16rem;
    text-align: center;
    p {
      margin-bottom: 0.1rem;
    }
  }
}
</style>
